import React from "react";
import Menubar from "../Components/Menubar";

function TermsOfUse() {
  return (
    <div className="overflow-hidden ">
      <Menubar />

      <div className="bg-gradient-to-b from-[#FCFBFF] py-36 px-5 lg:px-0 to-[#FCFBFF] flex justify-center w-full ">
        <div className="h-32" />
        <div className="w-full max-w-5xl">
          <h1 className="font-bold text-3xl lg:text-5xl max-w-4xl lg:mt-10 text-text leading-[40px]  lg:leading-[65px]">
            Nutzungsbedingungen für die mudioo-Plattform
          </h1>
          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 1 Geltungsbereich
          </h2>
          <div>
            Diese Nutzungsbedingungen gelten für die Nutzung der von der
            Nordflow Software GmbH (nachfolgend: „Lizenzgeber“) zur Verfügung
            gestellten mudioo-App und des mudioo-Content- Management-Systems
            (nachfolgend: „mudioo-cms“; mudioo-App und mudioo-cms zuasmmen
            nachfolgnd: „mudioo-Plattform“) sowie für alle über die
            mudioo-Plattform angebotenen und zur Verfügung gestellten Dienste
            und Leistungen.
          </div>
          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 2 Vertragsgegenstand
          </h2>
          <div>
            <p className="pb-3">
              (1) Gegenstand dieses Vertrags ist die auf die Vertragslaufzeit
              befristete, entgeltliche Nutzung der mudioo-Plattform und des
              nebst Einräumung der zu deren vertragsgemäßen Nutzung
              erforderlichen Rechte nach Maßgabe von § 3. Der Funktionsumfang
              der mudioo-Plattform wird wie folgt beschrieben:
              <ul className="list-disc ml-5  mt-2">
                <li>
                  Browser-basiertes Content-Management-System zur Erfassung und
                  Bearbeitung von Inhalten.
                </li>
                <li>
                  Verarbeitung von Texten, Bildern und Audio-Datein. Einbindung
                  von extern hochgeladenen Videos.
                </li>
                <li>
                  Übersetzungs- und Audioguide-Erstellung durch verschiedener
                  KI’s.
                </li>
                <li>
                  Generierung von Nummerierungen und QR-Codes für erstellte
                  Inhalte.
                </li>
                <li>Veröffentlichung aller Inhalte über die mudioo-App.</li>
                <li>
                  Bereitstellung der mudioo App über den Google-Play-Store und
                  den IOS App-Store.
                </li>
                <li>
                  Laufende Updates auf aktuelle technische Standards in
                  angemessenen Zeitabständen.
                </li>
                <li>
                  Ein Kundenservice, der bei Problemen oder Fragen angemessene
                  Hilfestellungen leistet.
                </li>
              </ul>
            </p>
            <p className="pb-3">
              (2) Der Lizenzgeber stellt über die gängigen Plattformen (Google
              Playstore, Apple App-Store) die mudioo-App zum kostenfreien
              Download zur Verfügung. Für die Nutzung der mudioo-Plattform ist
              eine Registrierung erforderlich.
            </p>
            <p className="pb-3">
              (3) Installations- und Konfigurationsleistungen sind nicht
              Gegenstand dieses Vertrags.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 3 Rechteeinräumung
          </h2>
          <div>
            <p className="pb-3">
              (1) Mit der mudioo-Plattform hat der Lizenznehmer die Möglichkeit,
              Exponate über das mudioo-cms mit von ihm in die mudioo-App
              eingestellten Daten und Inhalten (z.B. Texte, Bilder, Ton-
              /Videoaufnahmen) Dritten in Form von Multimediaguides zu
              präsentieren. Die Zahl der Exponate ist auf 50 Stück je
              Museum/Ausstellung/Veranstaltung, etc. begrenzt. Die für diesen
              Zweck erforderlichen Nutzungsrechte werden dem Lizenznehmer gegen
              Zahlung des Entgelts gemäß § 4 dieses Vertrages als
              nicht-ausschließliche, zeitlich auf die Laufzeit des Vertrages
              beschränkte, nicht übertragbare und nicht unterlizenzierbare
              Rechte zur Nutzung der mudioo-App eingeräumt.
            </p>
            <p className="pb-3">
              (2) Der Lizenznehmer ist ausschließlich dann berechtigt, die
              mudioo-Plattform zu vervielfältigen, zu bearbeiten oder zu
              dekompilieren, wenn dies gesetzlich zulässig ist und nur dann,
              sofern die hierzu notwendigen Informationen nicht auf Anfrage des
              Lizenznehmers durch den Hersteller der mudioo- App oder den
              Lizenzgeber zugänglich gemacht werden.
            </p>
            <p className="pb-3">
              (3) Über die in den Abs. 1 bis 2 genannten Fälle hinaus ist der
              Lizenznehmer nicht zur Vervielfältigung der mudioo-App berechtigt.
            </p>
            <p className="pb-3">
              (4) Verstößt der Lizenznehmer gegen eine der vorstehenden
              Bestimmungen, werden sämtliche im Rahmen dieses Vertrags erteilten
              Nutzungsrechte sofort unwirksam und fallen automatisch an den
              Lizenzgeber zurück. In diesem Fall hat der Lizenznehmer die
              Nutzung der mudioo-Plattform unverzüglich und vollständig
              einzustellen. Der Lizenzgeber ist in diesem Fall berechtigt, das
              Nutzerkonto des Lizenznehmers sofort zu sperren oder dauerhaft zu
              löschen.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 4 Entgelt, Fälligkeit und Verzug
          </h2>
          <div>
            <p className="pb-3">
              (1) Die Nutzung der mudioo-Plattform wird in verschiedenen
              Abo-Modellen angeboten (z.B. Monatsabo, Jahresabo). Soweit nicht
              im Angebot Preise angegeben sind, richten sich diese nach der
              jeweils bei Vertragsschluss geltenden Preisliste des Lizenzgebers.
              Alle Preise verstehen sich zzgl. der jeweils geltenden
              gesetzlichen Umsatzsteuer. Wird der Vertrag nicht am ersten Tag
              eines Kalendermonates geschlossen, berechnet sich die für den
              ersten Monat zu entrichtende Vergütung anteilig nach den
              verbleibenden Tagen des Monats, beginnend mit dem auf die
              Freischaltung des Nutzerkontos des Lizenznehmers folgenden Tag.
            </p>
            <p className="pb-3">
              (2) Der Vergütung wird bei monatlicher Zahlweise für den
              jeweiligen Monat im Voraus am 3. Werktag eines jeden Monats und
              bei jährlicher Zahlweise am 3. Werktag eines jeden Jahres fällig.
              Im ersten Monat wird die Vergütung mit Freischaltung des
              Nutzerkontos des Lizenznehmers fällig. Im Fall eines Jahresabos (§
              6 Abs. 2) kann der Lizenznehmer die Zahlung für mindestens ein
              Jahr oder einen längeren Zeitraum (z.B. drei oder fünf Jahre) im
              Voraus vornehmen. Ist eine Vorauszahlung für mindestens ein Jahr
              vereinbart, ist diese mit Freischaltung des Nutzerkontos fällig.
              Die Zahlweise wählt der Lizenznehmer im Auftragsformular aus. Für
              jede Vorauszahlung gilt im Fall der vorzeitigen Vertragsbeendigung
              gilt für die Erstattung von überzahlten Vorauszahlungen § 6 Abs.
              6.
            </p>
            <p className="pb-3">
              (3) Die Verzugszinsen betragen neun Prozent (9%) über dem jeweils
              gültigen Basiszinssatz.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 5 Pflichten des Lizenznehmers
          </h2>
          <div>
            <p className="pb-3">
              (1) Der Lizenznehmer hat die ihm übermittelten Zugangsdaten dem
              Stand der Technik entsprechend vor Zugriffen Dritter zu schützen
              und zu verwahren. Der Lizenznehmer wird dafür sorgen, dass eine
              Nutzung nur im vertraglich vereinbarten Umfang geschieht. Ein
              unberechtigter Zugriff ist dem Lizenzgeber unverzüglich
              mitzuteilen.
            </p>
            <p className="pb-3">
              (2) Der Lizenznehmer ist verpflichtet, auf dem zur Verfügung
              gestellten Speicherplatz keine Daten oder Inhalte abzulegen, deren
              Nutzung gegen geltendes Recht, behördliche Anordnungen, Rechte
              Dritter oder Vereinbarungen mit Dritten verstößt. Insbesondere ist
              untersagt,
              <ul className="list-disc ml-5  pb-1 mt-2">
                <li className="mb-2">
                  das Einstellen, die Verbreitung, das Angebot und die Bewerbung
                  pornografischer, gegen Jugendschutzgesetze, gegen
                  Datenschutzrecht und/oder gegen sonstiges Recht verstoßender
                  und/oder betrügerischer Inhalte, Dienste und/oder Produkte;
                </li>
                <li className="mb-2">
                  die Verwendung von Inhalten, durch die andere Dritte beleidigt
                  oder verleumdet werden;
                </li>
                <li className="mb-2">
                  die Nutzung, das Bereitstellen und das Verbreiten von
                  Inhalten, die gesetzlich geschützt oder mit Rechten Dritter
                  (zB Urheberrechte) belastet sind, ohne hierzu ausdrücklich
                  berechtigt zu sein.
                </li>
              </ul>
              Der Lizenznehmer ist für die von ihm eingestellten Inhalte voll
              verantwortlich. Der Lizenzgeber übernimmt keine Überprüfung der
              Inhalte auf Vollständigkeit, Richtigkeit, Rechtmäßigkeit,
              Aktualität, Qualität und Eignung für einen bestimmten Zweck.
            </p>
            <p className="pb-3">
              (3) Der Lizenznehmer erklärt und gewährleistet gegenüber dem
              Lizenzgeber, dass er der alleinige Inhaber sämtlicher Rechte an
              den von ihm in die mudioo-Plattform eingestellten Daten und
              Inhalten ist, oder aber anderweitig berechtigt ist (zB durch eine
              wirksame Erlaubnis des Rechteinhabers), diese einzustellen.
            </p>
            <p className="pb-3">
              (4) Der Lizenznehmer wird die Daten und Inhalte vor deren Ablage
              oder Nutzung in der Software auf Viren oder sonstige schädliche
              Komponenten prüfen und hierfür dem Stand der Technik entsprechende
              Maßnahmen (zB Virenschutzprogramme) einsetzen.
            </p>

            <p className="pb-3">
              (5) Der Lizenzgeber behält sich das Recht vor, das Einstellen von
              Inhalten abzulehnen und/oder bereits eingestellte Inhalte ohne
              vorherige Ankündigung zu bearbeiten, zu sperren oder zu entfernen,
              sofern das Einstellen der Inhalte durch den Lizenznehmer oder die
              eingestellten Inhalte selbst zu einem Verstoß gegen diese
              Nutzungsbedingungen oder gegen sonstiges Recht geführt haben oder
              konkrete Anhaltspunkte dafür vorliegen, dass es zu einem
              schwerwiegenden Verstoß gegen diese Nutzungsbedingungen oder gegen
              sonstiges Recht kommen wird. Der Lizenzgeber wird hierbei jedoch
              auf die berechtigten Interessen des Lizenznehmers Rücksicht nehmen
              und das mildeste Mittel zur Abwehr des Verstoßes wählen.
            </p>

            <p className="pb-3">
              (6) Der Lizenznehmer hat in eigener Verantwortung regelmäßig
              angemessene Datensicherungen vorzunehmen.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 6 Laufzeit und Kündigung
          </h2>
          <div>
            <p className="pb-3">
              (1)Im <span className="font-bold">Monatsabo:</span> Der Vertrag
              wird auf unbestimmte Zeit geschlossen. Er kann von jeder Partei
              mit einer Frist von zwei (2) Wochen zum Ende eines Kalendermonats
              gekündigt werden.
            </p>
            <p className="pb-3">
              (2) Im <span className="font-bold">Jahresabo:</span> Der Vertrag
              wird zunächst fest für eine Laufzeit von 12 Kalendermonaten
              geschlossen (Mindestvertragslaufzeit). Er verlängert sich jeweils
              um weitere 12 Kalendermonate, wenn er nicht vier (4) Wochen vor
              Ablauf der Mindestvertragslaufzeit bzw. der jeweils verlängerten
              Laufzeit von einer Partei gekündigt wird.
            </p>
            <p className="pb-3">
              (3) Der Vertrag kann darüber hinaus von jeder Partei ohne
              Einhaltung einer Frist aus wichtigem Grund schriftlich gekündigt
              werden. Ein wichtiger Grund, der den Lizenzgeber zur Kündigung
              berechtigt, liegt insbesondere vor, wenn der Lizenznehmer
              Nutzungsrechte des Lizenzgebers dadurch verletzt, dass er die
              mudioo-Plattform über das nach diesem Vertrag gestattet Maß hinaus
              nutzt oder der Lizenznehmer einer der Pflichten aus § 5 Abs. 1 bis
              4 verletzt und er die Verletzung auf eine Abmahnung des
              Lizenzgebers hin nicht innerhalb angemessener Frist abstellt.
            </p>

            <p className="pb-3">
              (4) Die Kündigung muss mindestens in Textform (E-Mail) oder über
              einen ggfs. in der App bereitgestellten Kündigungsbutton erfolgen.
            </p>

            <p className="pb-3">
              (5) Im Falle der Beendigung des Vertrages wird das Nutzerkonto des
              Lizenznehmers deaktiviert und werden die vom Lizenznehmer
              gespeicherten Daten und Inhalte nach Ablauf eines Zeitraums von
              drei Monaten unwiderruflich gelöscht.
            </p>

            <p className="pb-3">
              (6) Im Falle einer ordentlichen Kündigung nach Abs. 1 oder 2 kann
              der Nutzer die Erstattung bereits geleisteter Vorauszahlungen
              anteilig für die Zeit ab Vertragsbeendigung verlangen. Die
              Erstattung wird frühestens drei Monate ab Vertragsende fällig.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 7 Instandhaltung
          </h2>
          <div>
            <p className="pb-3">
              (1) Der Lizenzgeber leistet Gewähr für die Aufrechterhaltung der
              vertraglich vereinbarten Beschaffenheit der mudioo-Plattform
              während der Vertragslaufzeit sowie dafür, dass einer
              vertragsgemäßen Nutzung der mudioo-Plattform keine Rechte Dritter
              entgegenstehen. Der Lizenzgeber gewährt eine Gesamtverfügbarkeit
              der Leistungen von mindestens 98% im Monat am Übergabepunkt. Der
              Übergabepunkt ist der Routerausgang des Rechenzentrums des
              Lizenzgebers. Der Lizenzgeber wird auftretende Sach- und
              Rechtsmängel an der mudioo-Plattform in angemessener Zeit
              beseitigen. Der Lizenzgeber genügt seiner Pflicht zur
              Nachbesserung auch, indem er Updates über den Apple App-Store oder
              den Google Playstore zum Download und zur Installation zur
              Verfügung stellt.
            </p>
            <p className="pb-3">
              (2) Als Verfügbarkeit i.S.d. Abs. 1 gilt die Möglichkeit des
              Lizenznehmers, sämtliche Hauptfunktionen der Software zu nutzen.
              Wartungszeiten sowie Zeiten der Störung unter Einhaltung der
              Behebungszeit gelten als Zeiten der Verfügbarkeit der Software.
              Zeiten unerheblicher Störungen bleiben bei der Berechnung der
              Verfügbarkeit außer Betracht. Für den Nachweis der Verfügbarkeit
              sind die Messinstrumente des Lizenzgebers im Rechenzentrum
              maßgeblich.
            </p>
            <p className="pb-3">
              (3) Etwaige sonstige gesetzliche Ansprüche des Lizenznehmers gegen
              den Lizenzgeber bleiben unberührt.
            </p>

            <p className="pb-3">
              (4) Der Lizenznehmer ist verpflichtet, dem Lizenzgeber Mängel der
              mudioo-Plattform nach deren Entdeckung unverzüglich schriftlich
              anzuzeigen. Bei Sachmängeln erfolgt dies unter Beschreibung der
              Zeit des Auftretens der Mängel und der näheren Umstände.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 8 Haftung
          </h2>
          <div>
            <p className="pb-3">
              (1) Der Lizenzgeber haftet unbeschränkt
              <ul className="list-disc ml-5  pb-1 mt-2">
                <li className="mb-2">
                  bei Vorsatz oder grober Fahrlässigkeit,
                </li>
                <li className="mb-2">
                  für die Verletzung von Leben, Leib oder Gesundheit,
                </li>
                <li className="mb-2">
                  nach den Vorschriften des Produkthaftungsgesetzes sowie
                </li>
                <li className="mb-2">
                  im Umfang einer vom Lizenzgeber übernommenen Garantie.
                </li>
              </ul>
            </p>
            <p className="pb-3">
              (2) Bei leicht fahrlässiger Verletzung einer Pflicht, die
              wesentlich für die Erreichung des Vertragszwecks ist
              (Kardinalpflicht), ist die Haftung des Lizenzgebers der Höhe nach
              begrenzt auf den Schaden, der nach der Art des hier in Rede
              stehenden Geschäfts vorhersehbar und typisch ist.
            </p>
            <p className="pb-3">
              (3) Eine weitergehende Haftung des Lizenzgebers besteht nicht.
              Insbesondere besteht keine Haftung des Lizenzgebers für
              anfängliche Mängel, soweit nicht die Voraussetzungen der Abs. 1, 2
              vorliegen.
            </p>

            <p className="pb-3">
              (4) Die vorstehende Haftungsbeschränkung gilt auch für die
              persönliche Haftung der Mitarbeiter, Vertreter und Organe des
              Lizenzgebers.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 9 Vertraulichkeit
          </h2>
          <div>
            <p className="pb-3">
              (1) Die Parteien verpflichten sich, Vertrauliche Informationen der
              anderen Partei strikt und unbedingt geheim zu halten und durch
              angemessene technische und organisatorische Vorkehrungen zu
              schützen.
            </p>
            <p className="pb-3">
              (2) Von dieser Verpflichtung ausgenommen sind solche vertraulichen
              Informationen,
              <p className="pt-3">
                a) die dem Empfänger bei Abschluss des Vertrags nachweislich
                bereits bekannt waren oder danach von dritter Seite bekannt
                werden, ohne dass dadurch eine Vertraulichkeitsvereinbarung,
                gesetzliche Vorschriften oder behördliche Anordnungen verletzt
                werden;
              </p>
              <p className="pt-3">
                b) die bei Abschluss des Vertrags öffentlich bekannt sind oder
                danach öffentlich bekannt gemacht werden, soweit dies nicht auf
                einer Verletzung dieses Vertrags beruht;
              </p>
              <p className="pt-3">
                c) die aufgrund gesetzlicher Verpflichtungen oder auf Anordnung
                eines Gerichtes oder einer Behörde offen gelegt werden müssen.
                Soweit zulässig und möglich wird der zur Offenlegung
                verpflichtete Empfänger die andere
              </p>
            </p>
            <p className="pb-3">
              (3) Die Parteien werden nur solchen Beratern Zugang zu
              vertraulichen Informationen gewähren, die dem Berufsgeheimnis
              unterliegen oder denen zuvor den Geheimhaltungsverpflichtungen
              dieses Vertrags entsprechende Verpflichtungen auferlegt worden
              sind. Des Weiteren werden die Parteien nur denjenigen Mitarbeitern
              die vertraulichen Informationen offen legen, die diese für die
              Durchführung dieses Vertrags kennen müssen, und diese Mitarbeiter
              auch für die Zeit nach ihrem Ausscheiden in arbeitsrechtlich
              zulässigem Umfang zur Geheimhaltung verpflichten.
            </p>
          </div>

          <h2 className="font-bold text-2xl lg:text-3xl max-w-4xl mt-6 text-text leading-[40px]  lg:leading-[65px]">
            § 10 Sonstiges
          </h2>
          <div>
            <p className="pb-3">
              (1) Der Lizenznehmer darf Rechte und Pflichten aus oder im
              Zusammenhang mit diesem Vertrag nur nach schriftlicher Zustimmung
              des Lizenzgebers auf Dritte übertragen.
            </p>
            <p className="pb-3">
              (2) Eine Aufrechnung ist nur gegenüber unbestrittenen oder
              rechtskräftig festgestellten Forderungen des Lizenzgebers
              statthaft.
            </p>
            <p className="pb-3">
              (3) Änderungen und Ergänzungen des Vertrags bedürfen der
              Schriftform. Dies gilt auch für die Änderung oder Aufhebung dieser
              Klausel.
            </p>

            <p className="pb-3">
              (4) Allgemeine Geschäftsbedingungen des Lizenznehmers finden keine
              Anwendung.
            </p>

            <p className="pb-3">
              (5) Auf diesen Vertrag ist ausschließlich das deutsche Recht unter
              Ausschluss des Übereinkommens der Vereinten Nationen über Verträge
              über den internationalen Warenverkauf vom 11.4.1980 (UN-
              Kaufrecht) anzuwenden.
            </p>

            <p className="pb-3">
              (6) Erfüllungsort ist Kiel. Ausschließlicher Gerichtsstand ist
              Kiel.
            </p>

            <p className="pb-3">
              (7) Sollten einzelne Bestimmungen dieses Vertrags unwirksam sein,
              berührt dies die Gültigkeit der übrigen Bestimmungen grundsätzlich
              nicht. Die Parteien werden sich bemühen, anstelle der unwirksamen
              Bestimmung eine solche zu finden, die dem Vertragsziel rechtlich
              und wirtschaftlich am ehesten gerecht wird.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
