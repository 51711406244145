import React from "react";
import Menubar from "../Components/Menubar";

function Datapolicy() {
  return (
    <div className="overflow-hidden ">
      <Menubar />

      <div className="bg-gradient-to-b from-[#FCFBFF] py-36 px-5 lg:px-0 to-[#FCFBFF] flex justify-center w-full ">
        <div className="h-32" />
        <div className="w-full max-w-5xl">
          <h1 className="font-bold text-3xl lg:text-5xl max-w-4xl lg:mt-10 text-text leading-[40px]  lg:leading-[65px]">
            Datenschutzerklärung
          </h1>

          <p className="mt-8 leading-[28px]">
            Wir freuen uns über Ihr Interesse an unserer Datenschutzerklärung.
            Nordflow Software GmbH nimmt den Schutz Ihrer Daten sehr ernst. In
            dieser Richtlinie erfahren Sie, welche Informationen Nordflow
            Software GmbH sammelt, wenn Sie unsere Website und Dienste nutzen,
            und wie diese verwendet werden.
            <br />
            <br />
            Diese Datenschutzerklärung gilt für www.mudioo.de und www.mudioo.com
            (unsere "Website"), die von Nordflow Software GmbH,
            Schauenburgerstraße 116, 24118 Kiel, Deutschland ("Nordflow Software
            GmbH", "uns" oder "wir") als Datenverantwortlicher betrieben wird.
            Wenn Sie Fragen haben, können Sie uns unter +49157 89277139
            erreichen oder eine E-Mail an hallo@mudioo.de über unser
            Kontaktformular senden.
            <br />
            <br />
            Wir empfehlen Ihnen, diese Richtlinie zusammen mit unserer
            Cookie-Richtlinie zu lesen, und wenn Sie ein Nutzer unserer Dienste
            sind, lesen Sie bitte auch unsere Vereinbarung zur
            Datenverarbeitung.
          </p>

          <h1 className="font-bold mt-8 text-xl">Allgemeine Informationen</h1>
          <h2 className="mt-4 italic font-semibold">a) Welches Gesetz gilt?</h2>
          <p className="mt-2 leading-[28px]">
            Das Bundesdatenschutzgesetz ("BDSG") und die Allgemeine
            Datenschutzverordnung ("DSGVO") verlangen, dass wir Sie über die
            Verwendung Ihrer personenbezogenen Daten informieren.
          </p>

          <h2 className="mt-4 italic font-semibold">
            b) Was sind personenbezogene Daten?
          </h2>
          <p className="mt-2 leading-[28px]">
            Personenbezogene Daten sind alle Informationen über persönliche oder
            sachliche Verhältnisse, die sich auf eine identifizierte oder
            identifizierbare natürliche Person beziehen. Dazu gehören zum
            Beispiel Ihr Name, Ihr Geburtsdatum, Ihre E-Mail-Adresse, Ihre
            Postanschrift oder Ihre Telefonnummer sowie Online-Kennungen wie
            Ihre IP-Adresse. Im Gegensatz dazu sind Informationen allgemeiner
            Art, die nicht zur Feststellung Ihrer Identität verwendet werden
            können, keine personenbezogenen Daten. Dazu gehört zum Beispiel die
            Anzahl der Nutzer einer Website.
          </p>

          <h2 className="mt-4 italic font-semibold">
            c) Was sind Daten der besonderen Kategorie?
          </h2>
          <p className="mt-2 leading-[28px]">
            Besondere Datenkategorien sind personenbezogene Daten, die mehr
            Schutz erfordern, weil sie sensibel sind. Dazu gehören persönliche
            Daten, aus denen die rassische oder ethnische Herkunft, politische
            Meinungen, religiöse oder philosophische Überzeugungen, die
            Mitgliedschaft in einer Gewerkschaft, genetische Daten und
            biometrische Daten hervorgehen. Sowie Daten über die Gesundheit, das
            Sexualleben und die sexuelle Orientierung einer Person. Für die
            rechtmäßige Verarbeitung von Daten einer besonderen Kategorie ist
            die ausdrückliche Zustimmung zur Verarbeitung erforderlich.
          </p>

          <h2 className="mt-4 italic font-semibold">
            d) Was ist Verarbeitung?
          </h2>
          <p className="mt-2 leading-[28px]">
            "Verarbeitung" ist jeder Vorgang oder jede Reihe von Vorgängen, die
            mit personenbezogenen Daten durchgeführt werden, unabhängig davon,
            ob dies automatisch geschieht oder nicht. Der Begriff ist weit
            gefasst und umfasst praktisch jeden Umgang mit Daten.
          </p>

          <h2 className="mt-4 italic font-semibold">
            e) Was sind die Rechtsgrundlagen für die Verarbeitung?{" "}
          </h2>
          <p className="mt-2 leading-[28px]">
            Nach dem BDSG und der DSGVO sollten wir mindestens eine der
            folgenden Rechtsgrundlagen haben, um Ihre personenbezogenen Daten zu
            verarbeiten:
            <ul className="list-disc ml-6 mt-2">
              <li>Sie haben Ihre Einwilligung gegeben,</li>
              <li>
                die Daten sind für die Erfüllung eines Vertrags/vorvertraglicher
                Maßnahmen erforderlich,
              </li>
              <li>
                die Daten sind für die Erfüllung einer rechtlichen Verpflichtung
                erforderlich, oder
              </li>
              <li>
                die Daten sind für die Wahrung unserer berechtigten Interessen
                erforderlich, sofern Ihre Interessen nicht überwiegen.{" "}
              </li>
            </ul>
          </p>

          <h1 className="font-bold mt-8 text-xl">
            Daten, die wir automatisch erfassen
          </h1>
          <h2 className="mt-4 italic font-semibold">a) Protokolldaten</h2>
          <p className="mt-2 leading-[28px]">
            Jedes Mal, wenn Sie unsere Website besuchen, erhebt unser System
            automatisch die folgenden Daten vom besuchenden Gerät und speichert
            sie in einer so genannten Protokolldatei: (i) Name der abgerufenen
            Datei, (ii) Datum und Uhrzeit des Besuchs, (iii) übertragene
            Datenmenge, (iv) Meldung über den erfolgreichen Abruf, Art des
            Browsers und verwendete Version, (v) IP-Adresse (Identifikation des
            Geräts des Nutzers), (vi) Betriebssystem des besuchenden Geräts,
            (vii) Internet Service Provider des besuchenden Geräts, (viii)
            Website, von der aus Sie auf unsere Website zugreifen, und (ix)
            welche Seiten unserer Website Sie aufrufen. Die Rechtsgrundlage für
            diese Verarbeitung ist unser berechtigtes Interesse.
          </p>

          <h2 className="mt-4 italic font-semibold">
            b) Netzwerk zur Bereitstellung von Inhalten
          </h2>
          <p className="mt-2 leading-[28px]">
            Wir verwenden ein Content Delivery Network (CDN), um unsere
            Online-Inhalte zu verbreiten. Unser CDN ist ein Netzwerk von
            regional verteilten Servern unserer technischen Dienstleister, die
            über das Internet verbunden sind. Wenn Sie unsere Website besuchen,
            übermittelt der Browser Ihres Geräts Informationen an diese
            Dienstleister, die in entsprechenden Server-Logdateien gesammelt
            werden. Die Server-Protokolldateien werden in der Regel anonymisiert
            und dann ohne Personenbezug übermittelt. Die Server-Log-Dateien
            enthalten insbesondere i) Informationen über den verwendeten Browser
            und das Betriebssystem, ii) die zuvor besuchten Seiten (sog.
            Referral URL), iii) die IP-Adresse des verwendeten Geräts, iv) den
            Namen des Internet-Providers und v) das Datum, die Uhrzeit aller
            Seitenaufrufe einschließlich der übertragenen Datenmenge. Die
            Rechtsgrundlage für die Verarbeitung ist unser berechtigtes
            Interesse.
          </p>

          <h2 className="mt-4 italic font-semibold">c) Cookies</h2>
          <p className="mt-2 leading-[28px]">
            Wir verwenden auf unserer Website so genannte Cookies. Cookies sind
            Informationen, die von unserem Webserver oder Webservern Dritter an
            Ihren Webbrowser übermittelt und dort zum späteren Abruf gespeichert
            werden. Cookies können kleine Dateien oder andere Arten der
            Informationsspeicherung sein. Es gibt verschiedene Arten von
            Cookies: i) Wesentliche Cookies. Wesentliche Cookies sind Cookies,
            die eine korrekte und benutzerfreundliche Website ermöglichen; und
            ii) nicht-essentielle Cookies. Nicht-essentielle Cookies sind alle
            Cookies, die nicht unter die Definition der essentiellen Cookies
            fallen, wie z.B. Cookies, die dazu dienen, Ihr Verhalten auf einer
            Website zu analysieren ("analytische" Cookies) oder Cookies, die
            dazu dienen, Ihnen Werbung anzuzeigen ("Werbe-Cookies").
            <br />
            <br />
            Wie im Telekommunikations- und Telemediendatenschutz gesetz
            ("TTDSG") und in der Richtlinie über den Schutz der Privatsphäre und
            der elektronischen Kommunikation ("PECD") festgelegt, sollten wir
            für die Verwendung nicht wesentlicher Cookies eine Einwilligung
            einholen. Weitere Informationen über die von uns verwendeten Cookies
            finden Sie in unserer Cookie-Richtlinie. Die Rechtsgrundlage für die
            Verarbeitung ist unser berechtigtes Interesse und Ihre Zustimmung.
          </p>

          <h2 className="mt-4 italic font-semibold">d) Hosting</h2>
          <p className="mt-2 leading-[28px]">
            Zur Bereitstellung unserer Website nutzen wir die Dienste der Strato
            AG, Otto-Ostrowski-Straße 7, 10249 Berlin, Deutschland, die alle im
            Zusammenhang mit dem Betrieb unserer Website zu verarbeitenden Daten
            in unserem Auftrag verarbeitet. Die Rechtsgrundlage für die
            Datenverarbeitung ist unser berechtigtes Interesse an der
            Bereitstellung unserer Website.
          </p>

          <h1 className="font-bold mt-8 text-xl">
            Daten, die wir direkt erheben
          </h1>
          <h2 className="mt-4 italic font-semibold">
            a Kontaktaufnahme mit uns)
          </h2>
          <p className="mt-2 leading-[28px]">
            Zusätzlich zu Ihrem Namen, Firmennamen und Ihrer E-Mail-Adresse,
            IP-Adresse oder Telefonnummer, falls angegeben, erfassen wir im
            Allgemeinen den Kontext Ihrer Nachricht, der bestimmte
            personenbezogene Daten enthalten kann. Die persönlichen Daten, die
            wir erfassen, wenn Sie mit uns Kontakt aufnehmen, werden zur
            Bearbeitung Ihrer Anfrage verwendet, und die Rechtsgrundlage ist
            sowohl Ihre Zustimmung als auch Ihr Vertrag.
          </p>

          <h2 className="mt-4 italic font-semibold">
            b) Wenn Sie unsere Dienste nutzen
          </h2>
          <p className="mt-2 leading-[28px]">
            Wenn Sie unsere Dienste nutzen, sind Sie der für die
            Datenverarbeitung Verantwortliche und wir sind der Datenverarbeiter
            gemäß Kapitel 4 der DSGVO. Wenn wir Ihre personenbezogenen Daten als
            Datenverarbeiter oder, mit anderen Worten, in Ihrem Auftrag
            verarbeiten, verarbeiten wir die personenbezogenen Daten im
            Zusammenhang mit Ihrer Nutzung unserer Dienste gemäß Ihren
            Anweisungen und verwenden sie nur für die zwischen Ihnen und uns
            vereinbarten Zwecke. Weitere Informationen finden Sie in unserer
            Vereinbarung zur Datenverarbeitung.
            <br />
            <br />
            Wir verarbeiten die personenbezogenen Daten, die Sie bei der Nutzung
            unserer Dienste angeben, um unsere vertraglichen Leistungen zu
            erbringen und um die Sicherheit unserer Dienste zu gewährleisten und
            diese weiterzuentwickeln. Dazu gehören insbesondere unser Support,
            die Korrespondenz mit Ihnen, die Rechnungsstellung, die Erfüllung
            unserer vertraglichen, buchhalterischen und steuerlichen Pflichten.
            Dementsprechend werden die Daten auf der Grundlage der Erfüllung
            unserer vertraglichen Verpflichtungen sowie zur Erfüllung unserer
            gesetzlichen Pflichten verarbeitet.
            <br />
            <br />
            Wir stellen sicher, dass unsere Mitarbeiter nur bei Bedarf auf Ihre
            Daten zugreifen können, dass diese auf bestimmte Personen beschränkt
            sind und dass sie protokolliert und überprüft werden. Wir
            kommunizieren unsere Datenschutz- und Sicherheitsrichtlinien an
            unsere Mitarbeiter und setzen die Datenschutz- und
            Sicherheitsmaßnahmen strikt durch.
          </p>

          <h2 className="mt-4 italic font-semibold">
            c) Verwaltung, Finanzbuchhaltung, Büroorganisation,
            Kontaktmanagement.
          </h2>
          <p className="mt-2 leading-[28px]">
            Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie der
            Organisation unseres Geschäfts und der Erfüllung gesetzlicher
            Verpflichtungen, wie z.B. der Archivierung. In diesem Zusammenhang
            verarbeiten wir dieselben Daten, die wir bei der Erbringung unserer
            vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen
            sind unsere gesetzlichen Verpflichtungen und unser berechtigtes
            Interesse.
          </p>

          <h2 className="mt-4 italic font-semibold">
            d) Datenverwaltung und Kundenbetreuung
          </h2>
          <p className="mt-2 leading-[28px]">
            Für eine optimale Kundenbetreuung verwenden wir Ihren Namen, Ihren
            Firmennamen und Ihre E-Mail-Adresse sowie die Daten im Zusammenhang
            mit Ihrem Vertrag oder Ihrer Anfrage bei uns. Ihre Daten werden auf
            unserer Website und in unserem
            Customer-Relationship-Management-System gespeichert, das von
            HubSpot, 1 Sir John Rogerson's Quay, Dublin 2, Irland,
            bereitgestellt wird ("CRM-System"). Diese Datenverarbeitung basiert
            auf unserem berechtigten Interesse an der Bereitstellung unserer
            Dienstleistung.
          </p>

          <h2 className="mt-4 italic font-semibold">
            e) Service-Benachrichtigungen.{" "}
          </h2>
          <p className="mt-2 leading-[28px]">
            Indem Sie unsere Dienste nutzen, erklären Sie sich damit
            einverstanden, Benachrichtigungen und Nachrichten per E-Mail zu
            erhalten. Diese enthalten in der Regel allgemeine, profilbezogene
            und inhaltliche Informationen in Bezug auf Ihre Nutzung unserer
            Dienste. Unsere Systembenachrichtigungen dienen dazu, Ihr Erlebnis
            zu verbessern. Natürlich können Sie den Erhalt von
            Benachrichtigungen ablehnen, indem Sie die Anweisungen am Ende jeder
            Benachrichtigungs-E-Mail befolgen, die von HubSpot in unserem Namen
            versandt wird. Die Rechtsgrundlagen sind die Bereitstellung unserer
            Dienste für Sie und Ihre Zustimmung sowie unser berechtigtes
            Interesse.
          </p>

          <h2 className="mt-4 italic font-semibold">f) Newsletter</h2>
          <p className="mt-2 leading-[28px]">
            Wenn Sie dem Erhalt unseres Newsletters zugestimmt haben, verwenden
            wir Ihre E-Mail-Adresse, um Ihnen Informationen über Werbeaktionen,
            Dienstleistungen und Neuigkeiten sowie Umfragen zur
            Kundenzufriedenheit zu senden. Sie können Ihre Einwilligung zum
            Erhalt des Newsletters oder zur Erstellung von personalisierten
            Nutzerprofilen jederzeit mit Wirkung für die Zukunft widerrufen. Den
            Link zum Abbestellen finden Sie am Ende eines jeden Newsletters. Wie
            die oben erwähnten Service-Benachrichtigungen werden auch unsere
            Newsletter von HubSpot in unserem Auftrag versandt.
          </p>

          <h2 className="mt-4 italic font-semibold">g) Marketing</h2>
          <p className="mt-2 leading-[28px]">
            Wir können Sie zu diesen Zwecken über die Kommunikationskanäle
            kontaktieren, zu denen Sie Ihre Zustimmung gegeben haben. Unser
            Direktmarketing erfolgt in der Regel per E-Mail, kann aber auch über
            andere, weniger traditionelle oder neue Kanäle wie soziale Medien
            erfolgen (weitere Informationen finden Sie in unserer
            Cookie-Richtlinie). Diese Formen der Kontaktaufnahme werden von uns
            oder von unseren beauftragten Dienstleistern verwaltet. Jedes direkt
            adressierte Marketing, das von uns oder in unserem Namen verschickt
            oder durchgeführt wird, bietet Ihnen auch die Möglichkeit, sich
            abzumelden oder auszuschließen. Der von uns verwendete Dienstleister
            ist HubSpot.
          </p>

          <h1 className="font-bold mt-8 text-xl">
            Grundsätze der Verarbeitung personenbezogener Daten
          </h1>

          <h2 className="mt-4 italic font-semibold">
            a) Speicherung und Aufbewahrung
          </h2>
          <p className="mt-2 leading-[28px]">
            Zum Zeitpunkt der Datenerhebung, z.B. im Rahmen eines
            Vertragsverhältnisses, verarbeiten und speichern wir Persönliche
            Daten von Ihnen. Wir verarbeiten und speichern Ihre Persönlichen
            Daten nur, um den jeweiligen Verarbeitungszweck zu erreichen oder
            solange eine gesetzliche Aufbewahrungsfrist besteht. Sobald der
            Zweck erfüllt ist oder die Aufbewahrungsfrist abgelaufen ist, werden
            die entsprechenden Daten routinemäßig gelöscht.
          </p>

          <h2 className="mt-4 italic font-semibold">b) Sicherheit</h2>
          <p className="mt-2 leading-[28px]">
            Unsere Website verwendet eine SSL- oder TLS-Verschlüsselung, um die
            Sicherheit der Datenverarbeitung zu gewährleisten und die
            Übertragung vertraulicher Inhalte wie Bestellungen, Login-Daten oder
            Kontaktanfragen, die Sie an uns senden, zu schützen. Darüber hinaus
            haben wir zahlreiche Sicherheitsmaßnahmen ("technische und
            organisatorische Maßnahmen"), wie z.B. Verschlüsselung oder
            Zugriffsrechte nach dem Need-to-know-Prinzip, getroffen, um einen
            möglichst umfassenden Schutz der über unsere Website verarbeiteten
            personenbezogenen Daten zu gewährleisten.
            <br />
            <br />
            Dennoch können internetbasierte Datenübertragungen immer
            Sicherheitslücken aufweisen, so dass ein absoluter Schutz nicht
            garantiert werden kann. Und Datenbanken oder Aufzeichnungen, die
            personenbezogene Daten enthalten, können versehentlich oder durch
            unrechtmäßiges Eindringen beschädigt werden. Sobald wir von einer
            Datenschutzverletzung erfahren, werden wir alle betroffenen
            Personen, deren personenbezogene Daten möglicherweise kompromittiert
            wurden, so schnell wie möglich nach Bekanntwerden der Verletzung
            benachrichtigen.
          </p>

          <h2 className="mt-4 italic font-semibold">
            c)Besondere Kategorien von Daten{" "}
          </h2>
          <p className="mt-2 leading-[28px]">
            Wir verarbeiten keine besonderen Datenkategorien, es sei denn, die
            Nutzung unserer Dienste erfordert eine ausdrückliche Zustimmung.
          </p>

          <h2 className="mt-4 italic font-semibold">d) Minderjährige</h2>
          <p className="mt-2 leading-[28px]">
            Wir fordern keine persönlichen Daten von Minderjährigen und Kindern
            an und sammeln oder geben solche Daten nicht wissentlich an Dritte
            weiter.
          </p>

          <h2 className="mt-4 italic font-semibold">
            e) Automatisierte Entscheidungsfindung
          </h2>
          <p className="mt-2 leading-[28px]">
            Automatisierte Entscheidungsfindung ist der Prozess, bei dem eine
            Entscheidung durch automatisierte Mittel ohne menschliche
            Beteiligung getroffen wird. Automatisierte Entscheidungsfindung,
            einschließlich Profiling, findet nicht statt.
          </p>

          <h2 className="mt-4 italic font-semibold">f)Kein Verkauf</h2>
          <p className="mt-2 leading-[28px]">
            Wir verkaufen Ihre persönlichen Daten nicht.
          </p>

          <h2 className="mt-4 italic font-semibold">
            g)Weitergabe und Offenlegung{" "}
          </h2>
          <p className="mt-2 leading-[28px]">
            Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es
            sei denn, i) es ist für die Erbringung unserer Dienstleistungen
            erforderlich, einschließlich unserer Fulfillment-Partner und
            ausländischen Versandunternehmen, ii) Sie haben der Weitergabe
            zugestimmt, iii) oder wir sind gesetzlich dazu verpflichtet, z.B.
            durch einen Gerichtsbeschluss, oder es ist notwendig, um eine
            strafrechtliche oder juristische Untersuchung oder ein anderes
            juristisches Verfahren zu unterstützen, unabhängig davon, ob es sich
            um ein nationales oder internationales Verfahren handelt, oder um
            unseren berechtigten Interessen zu dienen.
          </p>

          <h2 className="mt-4 italic font-semibold">
            h) Internationale Übermittlung
          </h2>
          <p className="mt-2 leading-[28px]">
            Wir können Ihre personenbezogenen Daten an andere Unternehmen
            und/oder Mitarbeiter weitergeben, wenn dies für die in dieser
            Datenschutzerklärung beschriebenen Zwecke erforderlich ist. Um einen
            angemessenen Schutz Ihrer personenbezogenen Daten während solcher
            Übertragungen zu gewährleisten, haben wir vertragliche
            Vereinbarungen getroffen, die solche Übertragungen regeln. Außerdem
            ergreifen wir alle angemessenen technischen und organisatorischen
            Maßnahmen, um die von uns übertragenen personenbezogenen Daten zu
            schützen.{" "}
          </p>
          <h1 className="font-bold mt-8 text-xl">
            Ihre Rechte und Privilegien{" "}
          </h1>
          <h2 className="mt-4 italic font-semibold">a) Datenschutzrechte</h2>
          <p className="mt-2 leading-[28px]">
            Nach dem BDSG und der DSGVO können Sie die folgenden Rechte ausüben:
            <ul className="list-disc ml-6 mt-2 mb-3">
              <li>Recht auf Auskunft</li>
              <li>Recht auf Berichtigung</li>
              <li>Recht auf Widerspruch gegen die Verarbeitung</li>
              <li>Recht auf Löschung</li>
              <li>Recht auf Datenübertragbarkeit</li>
              <li>Recht auf Widerspruch</li>
              <li>Recht auf Widerruf der Einwilligung</li>
              <li>Recht auf eine Beschwerde bei einer Aufsichtsbehörde</li>
              <li>
                Recht, nicht einer Entscheidung unterworfen zu werden, die
                ausschließlich auf einer automatisierten Verarbeitung beruht.
              </li>
            </ul>
            Wenn Sie Fragen zu der Art der personenbezogenen Daten haben, die
            wir über Sie speichern, oder wenn Sie eines Ihrer Rechte ausüben
            möchten, kontaktieren Sie uns bitte.
          </p>

          <h2 className="mt-4 italic font-semibold">
            b) Aktualisierung Ihrer Daten
          </h2>
          <p className="mt-2 leading-[28px]">
            Wenn Sie der Meinung sind, dass die Daten, die wir über Sie
            gespeichert haben, unrichtig sind oder dass wir nicht mehr
            berechtigt sind, sie zu verwenden, und Sie die Berichtigung oder
            Löschung dieser Daten verlangen oder ihrer Verarbeitung
            widersprechen möchten, kontaktieren Sie uns bitte.
          </p>

          <h2 className="mt-4 italic font-semibold">
            c) Rücknahme Ihrer Zustimmung{" "}
          </h2>
          <p className="mt-2 leading-[28px]">
            Sie können eine erteilte Einwilligung jederzeit widerrufen, indem
            Sie sich mit uns in Verbindung setzen. Die Rechtmäßigkeit der bis
            zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
            unberührt.
          </p>

          <h2 className="mt-4 italic font-semibold">d) Antrag auf Auskunft </h2>
          <p className="mt-2 leading-[28px]">
            Wenn Sie ein Auskunftsersuchen über Ihre Daten stellen möchten,
            können Sie uns dies schriftlich mitteilen. Wir werden auf Auskunfts-
            und Berichtigungsersuchen so schnell wie möglich reagieren. Sollten
            wir nicht in der Lage sein, Ihre Anfrage innerhalb von dreißig (30)
            Tagen zu beantworten, werden wir Ihnen mitteilen, warum und wann wir
            in der Lage sein werden, Ihre Anfrage zu beantworten. Wenn wir nicht
            in der Lage sind, Ihnen die von Ihnen angeforderten persönlichen
            Informationen zur Verfügung zu stellen oder eine Korrektur
            vorzunehmen, werden wir Ihnen die Gründe dafür mitteilen.
          </p>

          <h2 className="mt-4 italic font-semibold">
            e) Beschwerde bei einer Aufsichtsbehörde
          </h2>
          <p className="mt-2 leading-[28px]">
            Sie haben das Recht, sich bei einer für den Datenschutz zuständigen
            Aufsichtsbehörde über unsere Verarbeitung personenbezogener Daten zu
            beschweren. Das Unabhängige Landeszentrum für Datenschutz ("ULD")
            ist die Dienststelle des Landesbeauftragten für den Datenschutz, der
            Datenschutzaufsichtsbehörde des Landes Schleswig-Holstein. Das ULD
            befindet sich in der Holstenstraße 98 in 24103 Kiel und ist
            telefonisch unter 0431 988-1200 oder per E-Mail unter
            mail@datenschutzzentrum.de zu erreichen. Die Website des ULD finden
            Sie unter www.datenschutzzentrum.de.
          </p>
          <h1 className="font-bold mt-8 text-xl">Änderungen und Fragen</h1>
          <p className="mt-2 leading-[28px]">
            Wir können diese Datenschutzerklärung von Zeit zu Zeit
            aktualisieren. Wenn wir Änderungen an dieser Datenschutzerklärung
            vornehmen oder unsere Verwendung Ihrer persönlichen Daten wesentlich
            ändern, werden wir die Datenschutzerklärung entsprechend
            überarbeiten und auch das Datum des Inkrafttretens am Ende dieses
            Abschnitts ändern. Wir empfehlen Ihnen, diese Datenschutzerklärung
            regelmäßig zu lesen, um sich darüber zu informieren, wie wir Ihre
            persönlichen Daten verwenden und schützen. Wenn Sie uns aus
            irgendeinem Grund bezüglich unserer Datenschutzpraktiken
            kontaktieren möchten, wenden Sie sich bitte an uns.
          </p>

          <h1 className="font-bold mt-8 text-xl">Datum des Inkrafttretens</h1>
          <p className="mt-2 leading-[28px]">Donnerstag, 6. April 2023</p>
        </div>
      </div>
    </div>
  );
}

export default Datapolicy;
